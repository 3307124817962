import React, { useState } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styled from "@emotion/styled"
import { toast } from "react-toastify"

import Button from "../form/Button"
import LabelRadio from "../form/LabelRadio"
import SimpleLayout from "../global/simpleLayout"

import { navigate } from "gatsby"
import useFirebase from "../../hooks/useFirebase"
import { useDispatch } from "react-redux"
import { addFbDocumentId, addDemographics } from "../../state/app"
import { useViewableHeight } from "../../hooks/useViewableHeight"
import { useCookies } from "react-cookie"
import { urls } from "../../data/urls"

const Card = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: max-content 1fr max-content;
  gap: 16px;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  background: ${props => props.theme.shade[100]};
  border-radius: ${props => props.theme.radius.xl};
  padding: 32px;
  text-align: center;
  overflow: scroll;
  flex-wrap: wrap;
  user-select: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  min-height: 100%;
  @media (max-width: 350px) {
    padding: 8px 16px;
  }
`
const Title = styled.h1`
  font-family: "Alt Goth", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => props.theme.wine};
  font-size: 2.4rem;
  margin: 0;
  @media (max-width: 350px) {
    font-size: 1.4rem;
    margin: 0 0 4px 0;
  }
`
const Subtitle = styled.h5`
  margin: 0 0 8px 0;
`

const Content = styled.div`
  height: 100%;
  overflow: auto;
  padding-top: 16px;
`
const Links = styled.div`
  display: grid;
  align-items: flex-end;
`
const Text = styled.div`
  color: ${props => props.theme.wine};
  p {
    margin: 0;
    @media (max-width: 350px) {
      font-size: 0.8rem;
    }
  }
`
const CustomLink = styled(AniLink)`
  cursor: pointer;
  color: ${props => props.theme.wine};
`
const Row = styled.div``
const Form = styled.form`
  display: grid;
  gap: 16px;
`
const CheckGrid = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
`

function AboutCard({ backgroundColor, textColor, destination }) {
  const firebase = useFirebase()
  const dispatch = useDispatch()

  const viewableHeight = useViewableHeight()

  const [age, setAge] = useState("")
  const [occupation, setOccupation] = useState("")
  const [cookies, setCookie] = useCookies([])

  function submitDemographics() {
    if (age.length === 0 || occupation.length === 0) {
      toast.error(
        "You need to make a selection for both 'Age' and 'Occupation'.",
        {
          position: toast.POSITION.BOTTOM_CENTER,
          hideProgressBar: false,
          closeOnClick: true,
          autoClose: 2500,
        }
      )
      return
    }

    const data = {
      lastUpdated: Date.now(),
      demographics: {
        date: Date.now(),
        age,
        occupation,
      },
    }

    setCookie("demographic", "true", { path: "/" })
    console.log(cookies)

    firebase
      .firestore()
      .collection("userData")
      .add(data)
      .then(docRef => {
        dispatch(addFbDocumentId(docRef.id))
        dispatch(addDemographics(data))
        navigate(destination, { replace: true })
      })
      .catch(function (error) {
        // TODO How are errors getting handled ?
        console.error("Error adding document: ", error)
      })
  }

  async function skipDemographics() {
    const data = {
      lastUpdated: Date.now(),
      demographics: {
        date: Date.now(),
        age: null,
        occupation: null,
      },
    }

    try {
      const docRef = await firebase.firestore().collection("userData").add(data)
      dispatch(addFbDocumentId(docRef.id))
    } catch (error) {
      console.error("Error adding document: ", error)
      toast.error("An error has occured.", {
        position: toast.POSITION.BOTTOM_CENTER,
        hideProgressBar: false,
        closeOnClick: true,
        autoClose: 2500,
      })
    } finally {
      navigate(destination, { replace: true })
    }
  }

  return (
    <SimpleLayout>
      <Card backgroundColor={backgroundColor} viewableHeight={viewableHeight}>
        <Content>
          <Title textColor={textColor}>About You</Title>
          <Text textColor={textColor}>
            <p>
              We’d love if you’d tell us a little bit about you — don’t worry,
              it’s totally anonymous.{" "}
              <CustomLink to={urls.TERMS}>Privacy policy</CustomLink>
            </p>
          </Text>
        </Content>
        <Form>
          <Row>
            <Subtitle>I am...</Subtitle>
            <CheckGrid>
              <LabelRadio
                id="16"
                name="age"
                onClick={() => {
                  setAge("<16")
                }}
              >
                &lt;16
              </LabelRadio>
              <LabelRadio
                id="16to19"
                name="age"
                onClick={() => {
                  setAge("16-19")
                }}
              >
                16-19
              </LabelRadio>
              <LabelRadio
                id="20to22"
                name="age"
                onClick={() => {
                  setAge("20-22")
                }}
              >
                20-22
              </LabelRadio>
              <LabelRadio
                id="23to29"
                name="age"
                onClick={() => {
                  setAge("23-29")
                }}
              >
                23-29
              </LabelRadio>
              <LabelRadio
                id="30plus"
                name="age"
                onClick={() => {
                  setAge("30+")
                }}
              >
                30+
              </LabelRadio>
            </CheckGrid>
          </Row>
          <Row>
            <Subtitle>I go to...</Subtitle>
            <CheckGrid>
              <LabelRadio
                id="School"
                name="place"
                onClick={() => {
                  setOccupation("School")
                }}
              >
                School
              </LabelRadio>
              <LabelRadio
                id="College"
                name="place"
                onClick={() => {
                  setOccupation("College")
                }}
              >
                College
              </LabelRadio>
              <LabelRadio
                id="Uni"
                name="place"
                onClick={() => {
                  setOccupation("Uni")
                }}
              >
                Uni
              </LabelRadio>
              <LabelRadio
                id="Work"
                name="place"
                onClick={() => {
                  setOccupation("Work")
                }}
              >
                Work
              </LabelRadio>
              <LabelRadio
                id="Other"
                name="place"
                onClick={() => {
                  setOccupation("Other")
                }}
              >
                Other
              </LabelRadio>
            </CheckGrid>
          </Row>
        </Form>
        <Links>
          <Button
            backgroundColor={props => props.theme.rose}
            onClick={submitDemographics}
          >
            Complete
          </Button>
          <Button
            backgroundColor={props => props.theme.shade[20]}
            buttonStyle={"inverted"}
            onClick={skipDemographics}
            // to={to} TODO may need to un comment this
          >
            Skip
          </Button>
        </Links>
      </Card>
    </SimpleLayout>
  )
}

export default AboutCard
