import React from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"

export default function SimpleLayout({ children }) {
  return (
    <>
      <ToastContainer />
      {children}
    </>
  )
}
