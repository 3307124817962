import React from "react"
import styled from "@emotion/styled"

import Layout from "../../components/global/layout"
import AboutCard from "../../components/block/AboutCard"
import { urls } from "../../data/urls"

const Container = styled.div`
  padding: 0 24px;
  min-height: 100%;
  @media (max-width: 350px) {
    padding: 0 16px;
  }
  animation: fadeIn 0.2s linear;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export default function index(props) {
  const destination = props?.location?.state?.destination || urls.KYV.QUIZ

  return (
    <Layout title="Before we start">
      <Container>
        <AboutCard destination={destination} />
      </Container>
    </Layout>
  )
}
