import React from "react"
import styled from "@emotion/styled"
import CheckGreen from "../../images/utility/check-green.svg"
import CheckLight from "../../images/utility/check-light.svg"

// This is to fix the sudden jerk up from input
const Outer = styled.div`
  position: relative;
`
const Text = styled.div`
  color: ${props => props.theme.rose};
  font-size: 0.6rem;
  text-align: center;
`
const Container = styled.label`
  display: flex;
  position: relative;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: ${props => props.theme.shade[80]};
  border-radius: ${props => props.theme.radius.default};
  align-items: center;
  transition: 0.2s;
  cursor: pointer;
  margin: 0 auto;
  &:hover {
    background: ${props => props.theme.shade[80]};
  }
`
const Checkmark = styled.span`
  &:after {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 18px;
    height: 14px;
    transform-origin: center;
    transition: 0.2s;
    background-image: url("${CheckLight}");
    background-position: center;
    background-repeat: no-repeat;
  }
`
const Input = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  border: 2px solid transparent;
  &:focus {
    + ${Container} {
      border: 2px solid ${props => props.theme.shade[50]};
    }
  }
  &:checked {
    + ${Container} {
      background: ${props => props.theme.teal};
      color: ${props => props.theme.shade[100]};
      font-weight: 500;
      ${Checkmark} {
        &:after {
          background-image: url("${CheckGreen}");
        }
      }
      ${Text} {
        color: ${props => props.theme.shade[100]};
      }
    }
    &:focus {
      + ${Container} {
        background: ${props => props.theme.teal};
      }
    }
  }
`

export default function Checkbox(props) {
  return (
    <Outer>
      <Input
        type="radio"
        id={props.id}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onClick={props.onClick}
        checked={props.checked}
      />
      <Container id={`${props.id}-container`} htmlFor={props.id}>
        <Checkmark id={`${props.id}-checkmark`} />
      </Container>
      <Text id={`${props.id}-text`}>{props.children}</Text>
    </Outer>
  )
}
